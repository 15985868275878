import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";

export interface User {
  name: string;
  surname: string;
  email: string;
  password: string;
  api_token: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user;
    this.errors = [];
    JwtService.saveToken(this.user.api_token);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Action
  [Actions.LOGIN](credentials) {
    return ApiService.post("login", credentials)
      .then(({ data }) => {
        //console.log(data);
        localStorage.setItem("userdata", JSON.stringify(data));
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        // console.log(response);
        Swal.fire("Error", response.data.message, "error");
        this.context.commit(Mutations.SET_ERROR, response.data.message);
      });
  }

  @Action
  [Actions.LOGIN2](credentials) {
    ApiService.setHeader();
    return ApiService.post("login2", credentials)
      .then(({ data }) => {
        //console.log(data);
        localStorage.setItem("userdata", JSON.stringify(data));
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        // console.log(response);
        Swal.fire("Error", response.data.message, "error");
        this.context.commit(Mutations.SET_ERROR, response.data.message);
      });
  }

  @Action
  [Actions.LOGOUT]() {
    localStorage.removeItem("userdata");
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  // @Action
  // [Actions.FORGOT_PASSWORD](url,data) {

  //   return ApiService.post(url, data)
  //   .then(({ data }) => {

  //     return data;
  //   })
  //   .catch(({ response }) => {
  //     //console.log(response)
  //     if (response.data.statusCode == 401 || response.data.statusCode == 403) {
  //       this.context.commit(Mutations.PURGE_AUTH);
  //       location.reload();
  //     }

  //     Swal.fire("Error", response.data.message, "error");
  //     return false;
  //   });
  // }

  @Action
  [Actions.FORGOT_PASSWORD]({ url, data }) {
    ApiService.setHeader();
    return ApiService.post(url, data)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        //console.log(response)
        if (
          response.data.statusCode == 401 ||
          response.data.statusCode == 403
        ) {
          this.context.commit(Mutations.PURGE_AUTH);
          location.reload();
        }

        Swal.fire("Error", response.data.message, "error");
        return false;
      });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      // const params = {
      //   params: {
      //     api_token: JwtService.getToken(),
      //   },
      // };
      //ApiService.post("verify_token", params as AxiosRequestConfig)
      ApiService.post("verify_token", {
        api_token: JwtService.getToken(),
      } as AxiosRequestConfig)
        .then(({ data }) => {
          //console.log();
          // console.log('data verify token',data);
          // localStorage.setItem('userdata', JSON.stringify());
          // JSON.parse(localStorage.getItem('userdata'))
          this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(({ response }) => {
          if (
            response.data.statusCode == 401 ||
            response.data.statusCode == 403
          ) {
            this.context.commit(Mutations.PURGE_AUTH);
            location.reload();
          }
          Swal.fire("Error", response.data.message, "error");
          return false;
          // this.context.commit(Mutations.SET_ERROR, response.data.errors);
          // this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  [Actions.GET_AUTH_USER]() {
    // console.log(this.user, 'dsdsd');
    this.context.commit(Mutations.SET_AUTH, this.user);
  }

  @Action
  [Actions.POST]({ url, data }) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(url, data)
        .then(({ data }) => {
          return data;
        })
        .catch(({ response }) => {
          //console.log(response)
          if (
            response.data.statusCode == 401 ||
            response.data.statusCode == 403
          ) {
            this.context.commit(Mutations.PURGE_AUTH);
            location.reload();
          }

          Swal.fire("Error", response.data.message, "error");
          return false;
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.GET]({ url }) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get(url)
        .then(({ data }) => {
          return data;
          // this.context.commit(data);
        })
        .catch(({ response }) => {
          Swal.fire("Error", response.data.message, "error");
          if (
            response.data.statusCode == 401 ||
            response.data.statusCode == 403
          ) {
            this.context.commit(Mutations.PURGE_AUTH);
            location.reload();
          }
          return false;
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.PUT]({ url, data }) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.put(url, data)
        .then(({ data }) => {
          return data;
        })
        .catch(({ response }) => {
          Swal.fire("Error", response.data.message, "error");
          if (
            response.data.statusCode == 401 ||
            response.data.statusCode == 403
          ) {
            this.context.commit(Mutations.PURGE_AUTH);
            location.reload();
          }
          return false;
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.DELETE]({ url }) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.delete(url)
        .then(({ data }) => {
          return data;
        })
        .catch(({ response }) => {
          if (
            response.data.statusCode == 401 ||
            response.data.statusCode == 403
          ) {
            this.context.commit(Mutations.PURGE_AUTH);
            location.reload();
          }
          Swal.fire("Error", response.data.message, "error");
          return false;
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  // @Action
  // [Actions.UPDATE_USER](payload) {
  //   ApiService.setHeader();
  //   return new Promise<void>((resolve, reject) => {
  //     ApiService.post("update_user", payload)
  //       .then(({ data }) => {
  //         this.context.commit(Mutations.SET_USER, data);
  //         resolve();
  //       })
  //       .catch(({ response }) => {
  //         this.context.commit(Mutations.SET_ERROR, response.data.errors);
  //         reject();
  //       });
  //   });
  // }
}
